<template>
  <div class="index-body">
    <div class="banner">
      <span>下载中心</span>
    </div>
    <div class="brief-introduction download">
      <el-table
        :data="dataList"
        ref="expandstable"
        :row-key="(row) => row.id"
        :expand-row-keys="expandRows"
        style="width: 100%"
      >
        <el-table-column label="title" prop="title"> </el-table-column>
        <el-table-column type="expand" width="1">
          <template slot="header">
            <el-button type="text" size="mini">{{ "展开" }} </el-button>
          </template>
          <!-- <el-table-column
         
            label="操作"
            width="120">
            <template slot-scope="scope">
                <el-button
                @click.native.prevent="deleteRow(scope.$index, tableData)"
                type="text"
                size="small">
                移除
                </el-button>
            </template>
            </el-table-column> -->

          <template slot-scope="props">
            <div class="expand">
              <div
                class="expand-title"
                :ref="'expand' + props.row.id"
                :class="open1 ? 'hiddenTwo' : ''"
              >
                {{ decode(props.row.content)}}
             

                <i
                  v-if="open1"
                  class="checekmore"
                  @click="goDetail(props.row.id)"
                  >查看更多</i
                >
              </div>
              <el-form
                label-position="left"
                v-for="(item, i) in props.row.attachUrl"
                :key="item.id"
                inline
                class="demo-table-expand"
              >
                <el-form-item label="">
                  <span>{{ item.name }} &nbsp;</span>
                </el-form-item>
                <el-form-item label="" style="width: 0.85rem">
                  <span>{{ (item.size / 1024 / 1024).toFixed(2) }}MB</span>
                </el-form-item>
                <el-form-item label="" width="150">
                  <div
                    class="downloadIcon"
                    @click="downloadFile(i, props.row.id)"
                  >
                    下载
                    <img src="../../assets/recommend/downloadIcon.png" alt="" />
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <div @click="expandsHandle(scope.row)" v-if="decode(scope.row.content)  ||  scope.row.attachUrl.length >= 2" style="cursor: pointer">
              <span>展开</span>
              <i
                v-if="!scope.row.active"
                style="font-weight: 800; margin-left: 0.08rem"
                class="el-icon-arrow-down"
              ></i>
              <i
                v-if="scope.row.active"
                style="font-weight: 800; margin-left: 0.08rem"
                class="el-icon-arrow-up"
              ></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="name" width="120">
          <template slot-scope="scope">
            <div class="downloadIcon" @click="downloadAll(scope.row.id)">
              <span> 下载 </span>
              <img src="../../assets/recommend/downloadIcon.png" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="publishTime" width="300" align="right">
        </el-table-column>
      </el-table>

      <div class="pagination">
        <el-button :disabled="dataFrom.firstPageDisabled" @click="goPageOne()">
          首页</el-button
        >
        <el-pagination
          :pager-count="5"
          background
          :current-page="dataFrom.page"
          :page-size="dataFrom.limit"
          layout="prev, pager, next"
          :total="dataFrom.total"
          @current-change="pageCurrentChangeHandle"
        >
        </el-pagination>
        <el-button :disabled="dataFrom.lastPageDisabled" @click="goPageLast()"
          >末页</el-button
        >
        <span class="go">跳转</span>

        <input :value="goPage" @input="handleInput" type="text" />
        <span class="page">页</span>
        <el-button @click="go()">跳转</el-button>
      </div>
    </div>
    <ul class="Phone-download" ref="Main5">
      <li v-for="item in dataList" :key="item.id">
        <div class="msg">
          <span>{{ item.title }}</span>
          <span>{{ item.publishTime }}</span>
        </div>
        <div class="buttons">
          <div @click="open(item)">
            {{ item.open ? "关闭" : "展开" }}
            <i class="el-icon-arrow-down" v-if="!item.open"></i
            ><i class="el-icon-arrow-up" v-else></i>
          </div>
          <div @click="downloadAll(item.id)">
            <span> 下载 </span>
            <img src="../../assets/recommend/downloadIcon.png" alt="" />
          </div>
        </div>

        <div class="Phoneexpand" v-show="item.open">
          <div class="expand-title" :ref="'expandphone' + item.id">
            <!-- <div class="content" style="min-height: 30px">
              <input type="checkbox" id="exp" />
              <div
                class="textLsjc"
                :class="open1 ? 'textLsjcOpen' : ''"
                id="jyfw"
              >
                <span
                  ><label class="jyfwbtn" @click="goDetail(item.id)"></label
                ></span>
                <p style="word-break: break-word">
                  {{ decode(item.content) }}{{ open1 }}
                </p>
              </div>
            </div> -->
            <section class="neirong" :class="open1 ? 'twohidden' : ''">
              {{ decode(item.content) }} 
              <span class="more" v-if="open1" @click="goDetail(item.id)" >更多</span>
            </section>
          </div>
          <!-- <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="" style="width: 3.16rem">
                  <span>姓名姓名姓名姓名姓名姓名姓名姓名姓名姓名姓名姓名姓名</span>
                </el-form-item>
                <el-form-item label="" style="width: 0.85rem">
                  <span>1.22rem</span>
                </el-form-item>
                <el-form-item label="" width="150">
                  <div class="downloadIcon">
                    下载
                    <img src="../../assets/recommend/downloadIcon.png" alt="" />
                  </div>
                </el-form-item>
              </el-form> -->
          <ul>
            <li v-for="(urlMsg, i) in item.attachUrl" :key="urlMsg.uid">
              <div class="expand-name">
                <span>{{ urlMsg.name }}</span>
              </div>
              <div class="downloadIcon" @click="downloadFile(i, item.id)">
                <span>{{ (urlMsg.size / 1024 / 1024).toFixed(2) }}MB</span> 下载
                <img src="../../assets/recommend/downloadIcon.png" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <section ref="bottom"></section>
  </div>
</template>

 
<script>
import Swiper from "swiper";
export default {
  name: "vue-index",
  data() {
    return {
      open1: false, // 内容状态是否打开
      expandRows: [],

      textHeight: null, // 框中内容的高度
      msg: "",
      dataList: [],
      total: 0,
      tableData: [
        {
          id: "12987122",
          name: "好滋好味鸡蛋仔",
          category: "江浙小吃、小吃零食",
          desc: "荷兰优质淡奶，奶香浓而不腻",
          address: "上海市普陀区真北路",
          shop: "王小虎",
          shopId: "10333",
        },
        {
          id: "12987123",
          name: "好滋好味鸡蛋仔",
          category: "江浙小吃、小吃零食",
          desc: "荷兰优质淡奶，奶香浓而不腻",
          address: "上海市普陀区真北路",
          shop: "王小虎",
          shopId: "10333",
        },
        {
          id: "12987125",
          name: "好滋好味鸡蛋仔",
          category: "江浙小吃、小吃零食",
          desc: "荷兰优质淡奶，奶香浓而不腻",
          address: "上海市普陀区真北路",
          shop: "王夫妻店",
          shopId: "10333",
        },
        {
          id: "12987126",
          name: "好滋好味鸡蛋仔",
          category: "江浙小吃、小吃零食",
          desc: "荷兰优质淡奶，奶香浓而不腻",
          address: "上海市普陀区真北路",
          shop: "王小虎",
          shopId: "10333",
        },
        {
          id: "12987126",
          name: "好滋好味鸡蛋仔",
          category: "江浙小吃、小吃零食",
          desc: "荷兰优质淡奶，奶香浓而不腻",
          address: "上海市普陀区真北路",
          shop: "王小虎",
          shopId: "10333",
        },
        {
          id: "12987126",
          name: "好滋好味鸡蛋仔",
          category: "江浙小吃、小吃零食",
          desc: "荷兰优质淡奶，奶香浓而不腻",
          address: "上海市普陀区真北路",
          shop: "王小虎",
          shopId: "10333",
        },
        {
          id: "12987126",
          name: "好滋好味鸡蛋仔",
          category: "江浙小吃、小吃零食",
          desc: "荷兰优质淡奶，奶香浓而不腻",
          address: "上海市普陀区真北路",
          shop: "王小虎",
          shopId: "10333",
        },
        {
          id: "12987126",
          name: "好滋好味鸡蛋仔",
          category: "江浙小吃、小吃零食",
          desc: "荷兰优质淡奶，奶香浓而不腻",
          address: "上海市普陀区真北路",
          shop: "王小虎",
          shopId: "10333",
        },
        {
          id: "12987126",
          name: "好滋好味鸡蛋仔",
          category: "江浙小吃、小吃零食",
          desc: "荷兰优质淡奶，奶香浓而不腻",
          address: "上海市普陀区真北路",
          shop: "王小虎",
          shopId: "10333",
        },
      ],
      dataFrom: {
        page: 1,
        limit: 10,
        status: 1,

        firstPageDisabled: false,
        lastPageDisabled: false,
      },
      expandStatus: true,
      goPage: "",
    };
  },
  created() {
    this.query();
    this.scrollWindow();
  },

  mounted() {
    new Swiper(".swiper-container", {
      loop: true,
      // // 如果需要分页器
      // pagination: '.swiper-pagination',
      // // 如果需要前进后退按钮
      // nextButton: '.swiper-button-next',
      // prevButton: '.swiper-button-prev',
      // // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
    });
    // this.getHeight()
  },
  methods: {
    goDetail(id) {
      // this.$router.push({
      //   path: "/download/detail",
      //   query: { id: id },
      // });

       let url = this.$router.resolve({
         path: "/download/detail",
        query: { id: id },
      });
      // console.log(item,'url');
      window.open(url.href, "_blank");
    },
    async getHeight(id) {
      
      await this.$nextTick(() => {
        // 这里具体行数可依据需求自定义
        let lineHeight = 0.22 * 2;
        this.textHeight = `${lineHeight}rem`;
        let fontSize = getComputedStyle(window.document.documentElement)[
          "font-size"
        ];

        //以上方法返回的font-size会带单位px，如果不想要px可以做一下处理
        // +fontSize.slice(0, fontSize.indexOf('px'))

        setTimeout(() => {
        console.log(
           this.$refs["expand" + id].offsetHeight ,
            Number(lineHeight) * fontSize.slice(0, fontSize.indexOf("px")),
          "this.$refs.content.offsetHeight",
          this.$refs["expand" + id],
          id,
          +fontSize.slice(0, fontSize.indexOf("px"))
        );
        if (
          this.$refs["expand" + id].offsetHeight >
          Number(lineHeight) * fontSize.slice(0, fontSize.indexOf("px"))
        ) {
          this.open1 = true;
        } else {
          this.open1 = false;
        }

        }, 10);
      });
    },
    async getPhoneHeight(id) {
  
      await this.$nextTick(() => {
        // 这里具体行数可依据需求自定义
        let lineHeight = 0.22 * 2;
        this.textHeight = `${lineHeight}rem`;
        let fontSize = getComputedStyle(window.document.documentElement)[
          "font-size"
        ];

        //以上方法返回的font-size会带单位px，如果不想要px可以做一下处理
        // +fontSize.slice(0, fontSize.indexOf('px'))

        setTimeout(() => {
          console.log(
            this.$refs["expandphone" + id][0].offsetHeight,

            Number(lineHeight) * fontSize.slice(0, fontSize.indexOf("px"))
          );

          if (
            this.$refs["expandphone" + id][0].offsetHeight >
            Number(lineHeight) * fontSize.slice(0, fontSize.indexOf("px"))
          ) {
            console.log(true);
            this.open1 = true;
          } else {
            this.open1 = false;
            console.log(false);
          }
        }, 1);
      });
    },
    checkMore(id) {
      let status = false;
      this.open1.forEach((open) => {
        if (open.id == id) {
          status = true;
        }
      });
      return status;
    },
    goPageOne() {
      this.dataFrom.page = 1;
      this.query();
    },
    goPageLast() {
      this.dataFrom.page = Math.ceil(this.dataFrom.total / this.dataFrom.limit);
      this.query();
    },
    // async downloadAll(id) {
    //    let { data: res } = await this.$http.get('/downloadcenter/download?id='+id, {

    //       })
    //         //res为返回结果
    //       let blob = new Blob([res], {type: "application/octet-stream", });
    //       // let time =
    //       // let fileName = name + this.currentDate()  + ".xls";
    //       if (window.navigator.msSaveOrOpenBlob) {
    //         navigator.msSaveBlob(blob);
    //       } else {
    //         var link = document.createElement("a");//定义一个a标签
    //         link.href = window.URL.createObjectURL(blob);//需要生成一个 URL 来实现下载，链接到blob上
    //         // link.download = fileName;//下载后的文件名称
    //         link.click(); //模拟在按钮上实现一次鼠标点击
    //         window.URL.revokeObjectURL(link.href); //释放 URL 对象
    //       }

    // },
    downloadAll(id) {
      // 创建隐藏的可下载链接
      let eleLink = document.createElement("a");

      eleLink.href = `http://103.254.70.3:80/admin/downloadcenter/download?id=${id}`;
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);

      // this.$httpzip
      //   .get("/downloadcenter/download?id=" + id, {})
      //   .then(({ data: res }) => {
      //     // if (res.code) {
      //     //   return this.$message.error(res.msg);
      //     // }
      //     //res为返回结果
      //     let blob = new Blob([res], { type: "application/octet-stream" });
      //     // let time =
      //     // let fileName = 1 + ".zip";
      //     if (window.navigator.msSaveOrOpenBlob) {
      //       navigator.msSaveBlob(blob);
      //     } else {
      //       var link = document.createElement("a"); //定义一个a标签
      //       link.href = window.URL.createObjectURL(blob); //需要生成一个 URL 来实现下载，链接到blob上
      //       // link.download = fileName; //下载后的文件名称
      //       link.click(); //模拟在按钮上实现一次鼠标点击
      //       window.URL.revokeObjectURL(link.href); //释放 URL 对象
      //     }
      //     this.$message({
      //       message: '导出成功',
      //       type: "success",
      //       duration: 500,
      //       onClose: () => {
      //          console.log("111")
      //       },
      //     });
      //   })
      //   .catch((err) => {
      //     console.log(err, "res");
      //   });
    },

    scrollWindow() {
      window.addEventListener("scroll", () => {
        if (
          navigator.userAgent.match(
            /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
          )
        ) {
          //显示移动端的代码
          this.getMainBox();
        }
      });
    },
    async getMainBox() {
      let Main = this.$refs.Main5;
      let bottom = this.$refs.bottom; //底部的相关DOM
      // console.log(Main.offsetTop);      //父盒子距离浏览器顶部的高度
      // console.log(window.innerHeight);  //浏览器的高度，即页面窗口的高度
      if (!Main && !Main.clientHeight) {
        return;
      } //父盒子的高度(加上padding和margin)
      console.log(window.scrollY); //浏览器右边的滚动条滚动距离
      if (
        Math.abs(
          Main.clientHeight -
            window.innerHeight -
            (window.scrollY - Main.offsetTop - bottom.clientHeight)
        ) <= 10
      ) {
        console.log("我滑动到底部了");
        this.dataFrom.page += 1;
        let { data: res } = await this.$http.get("/downloadcenter/homePage", {
          params: {
            ...this.dataFrom,
          },
        });
        this.dataList = this.dataList.concat(res.data.list);
        console.log(this.dataList, res.data.list, "this.dataLis");

        // alert('12323132')
        //这里在运用在获取新的相关数据即可
      }
    },
    expandsHandle(row) {
      // this.expandStatus = !this.expandStatus
      this.dataList.forEach((e) => {
        if (!e.active) {
          e.active = false;
          if (e.id == row.id) {
            e.active = true;
          }
        } else {
          e.active = false;
        }
      });
      if (row.id == this.expandRows[0]) {
           this.open1 = false;
        this.expandRows = [];
      } else {
           this.open1 = false;
        this.expandRows = [row.id];
        this.getHeight(row.id);
      }

      this.$refs.expandstable.toggleRowExpansion(row);

      // this.getHeight(row.id);
    },
    open(item) {
      this.dataList.forEach((e) => {
        if (!e.open) {
          e.open = false;
          if (e.id == item.id) {
            e.open = true;
         this.open1 = false;
            this.getPhoneHeight(item.id);
          }
        } else {
             this.open1 = false;
          e.open = false;
        }
        // if (element.id == item.id) {
        //   element.open = !element.open;
        //   console.log(element.open, element.attachUrl, " element.attachUrl");
        // }
      });
      // this.dataList =  this.dataList
      this.$forceUpdate();
    },
    async downloadFile(fileIndex, id) {
      // var blob = new Blob(['Hello, world!'], {
      //   type: 'text/plain;charset=utf-8',
      // });

      // 存在浏览器兼容性
      // let href = URL.createObjectURL(blob);

      let eleLink = document.createElement("a");

      eleLink.href = `http://103.254.70.3:80/admin/downloadcenter/download?id=${id}&fileIndex=${fileIndex}`;
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);

      // URL.revokeObjectURL(href); //释放掉blob对象
    },

    query() {
      console.log("resdata");

      this.$http
        .get("/downloadcenter/homePage", {
          params: {
            ...this.dataFrom,
          },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            this.dataList = [];
            this.total = 0;
            return this.$message.error(res.msg);
          }
          this.dataList = res.data.list;
          this.dataList.forEach((element) => {
            element.attachUrl = JSON.parse(element.attachUrl);
            element.open = false;
            console.log(element.attachUrl, " element.attachUrl");
          });
          console.log(this.dataList, "element.attachUrl");
          this.dataFrom.total = res.data.total;
        })
        .catch(() => {});
    },
    handleInput(value) {
      this.goPage = +value.data;
    },

    go() {
      this.dataFrom.page = this.goPage;
      this.query();
    },

    selectType(status) {
      this.dataFrom.status = status;
      this.query();
    },
    select(type) {
      this.dataFrom.type = type;
      this.query();
    },
    pageCurrentChangeHandle(index) {
      this.dataFrom.page = index;
      this.query();
    },
    decode(base64) {
      // 对base64转编码
      // var decode = atob(base64);
      // // 编码转字符串
      // var str = decodeURI(decode);
     var test = base64 && base64.replaceAll(/<[^>]+>/g, "");
      return test;
    },
  },
  watch: {
    dataFrom: {
      handler(newVal) {
        let pages = Math.ceil(newVal.total / newVal.limit);
        this.goPage = this.dataFrom.page;
        if (pages === 0) {
          // 数据(totalResult)为0
          this.dataFrom.firstPageDisabled = true; // 首页按钮是否禁用
          this.dataFrom.lastPageDisabled = true; // 末页按钮是否禁用
        } else {
          this.dataFrom.firstPageDisabled = newVal.page === 1;
          this.dataFrom.lastPageDisabled = newVal.page === pages;
          console.log(
            pages,
            this.dataFrom.firstPageDisabled,
            this.dataFrom.lastPageDisabled,
            "pages"
          );
        }
      },
      // 一进页面就执行
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  margin-top: 0.56rem;
  height: 3.3rem;
  background: linear-gradient(180deg, #b20103 0%, #f8f8f8 100%);

  span {
    position: relative;
    left: 0.84rem;
    top: 0.64rem;
    font-size: 0.48rem;
    font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
    font-weight: bold;
    color: #ffffff;
    line-height: 0.56rem;
  }
}
.hiddenTwo {
  height: 0.44rem;
  overflow: hidden;
  position: relative;
  .checekmore {
    position: absolute;
    right: 0rem;
    bottom: 0rem;
    display: block;
    line-height: 0.22rem;
    background: #ffffff;
  }
}
.Phone-download {
  display: none;
}
.expand-title {
  font-size: 0.14rem;

  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 0.22rem;
  // padding-right: .24rem;
  i {
    font-size: 0.12rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #b20103;
    line-height: 0.17rem;
    cursor: pointer;
  }
}
.download {
  /deep/ .el-table thead {
    display: none;
  }
  /deep/ .el-icon-arrow-right:before {
    // color: var(--backgroundColor);
  }

  /deep/ .el-table .el-table__cell {
    height: 0.54rem;
  }
  .expand {
    margin: 0rem 0.24rem;
  }
  /deep/ .el-table__expanded-cell {
    margin: 0;
    background: #f8f8f8;

    padding: 0.17rem 0.24rem;
    padding-bottom: 0.29rem;
  }
  /deep/ .el-table__body-wrapper {
    // height: 5rem;
    // overflow-y: auto;
  }
  /deep/ .el-table__expand-icon {
    display: none;
  }
}
/deep/ .el-table .cell {
  font-size: 0.14rem;

  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  line-height: 0.22rem;
}
/deep/ .el-table::before {
  display: none;
}
.demo-table-expand {
  margin-top: 0.16rem;
  font-size: 0;
  display: flex;
  align-items: center;
  font-size: 0.14rem;

  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  line-height: 0.22rem;
  /deep/ .el-form-item__content {
    font-size: 0.14rem;
    line-height: 0.22rem;
  }
}
.demo-table-expand label {
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
}

/deep/ .el-icon-arrow-down:before {
  color: var(--backgroundColor);
}
/deep/ .el-icon-arrow-up:before {
  color: var(--backgroundColor);
}

/deep/ .el-menu-item {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  height: 0.48rem;
  line-height: 0.48rem;
  padding-left: 0.64rem !important;
  position: relative;
  margin-bottom: 0.08rem;
}
/deep/ .el-submenu__title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  height: 0.56rem;
  line-height: 0.48rem;
  padding-left: 0.32rem !important;
}
/deep/.el-menu-item:hover {
  background: #eeeeee;
}
/deep/.el-submenu__title:hover {
  background: #ffffff;
}
/deep/ .el-menu-item.is-active {
  background: rgba(195, 40, 43, 0.1);
}

.el-menu-item.is-active::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "";
  width: 0.04rem;
  height: 0.48rem;
  background: #c3282b;
}

.pagination {
  position: absolute;
  right: 0.32rem;
  //   margin-top: 0.16rem;
  bottom: 0.32rem;
  display: flex;
  align-items: center;
  span {
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0.2rem;
    margin-right: 0.16rem;
  }
  .go {
    margin: 0 0.08rem;
  }
  .page {
    margin-left: 0.14rem;
  }
  input {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    text-align: center;
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
  input:focus-visible {
    outline: none;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-input {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  // /deep/ .el-input__inner {
  //   width: 0.32rem;
  //   height: 0.32rem;
  //   background: #ffffff;
  //   border-radius: 0.04rem;
  //   border: 0.01rem solid #eeeeee;
  // }

  /deep/.el-button {
    width: 0.6rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    // line-height: .02rem;

    padding: 0;
  }
  /deep/.el-button span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }

  /deep/ .el-pagination .btn-prev .el-icon {
    content: "首页";
      font-weight: 500;
  }
  /deep/ .el-icon-arrow-left:before {
      content: "上一页";                font-weight: 500;
         font-size: .14rem;
  }
  /deep/ .el-icon-arrow-right::before {
        content: "下一页";            font-weight: 500;
         font-size: .14rem;
  }

  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .btn-next {
    width: 0.74rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: var(--backgroundColor);
  }
  /deep/ .el-pagination.is-background .el-pager li {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.14rem;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--backgroundColor);
  }
  /deep/.el-button span {
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0.2rem;
  }
  /deep/ .el-button.is-disabled span {
    color: #c0c4cc;
  }
  .el-pagination .btn-prev .el-icon {
    content: "首页";
      font-weight: 500;
  }
  /deep/ .el-icon-arrow-left:before {
      content: "上一页";                font-weight: 500;
         font-size: .14rem;
  }
  /deep/ .el-icon-arrow-right::before {
        content: "下一页";            font-weight: 500;
         font-size: .14rem;
  }

  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .btn-next {
    width: 0.74rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: var(--backgroundColor);
  }
  /deep/ .el-pagination.is-background .el-pager li {
    width: 0.32rem;
    height: 0.32rem;
    line-height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.14rem;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--backgroundColor);
    color: #ffffff;
  }
}

.brief-introduction {
  position: relative;
  top: -1.2rem;
  box-sizing: border-box;
  margin: 0 0.64rem;
  width: 13.1rem;

  //   height: 6.08rem;
  background: #ffffff;
  border-radius: 0.02rem 0.02rem 0rem 0.02rem;
  margin-bottom: -0.88rem;

  padding: 0.4rem;
  padding-top: 0.24rem;
  padding-bottom: 0.86rem;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 50%;
      display: flex;
      margin-bottom: 0.16rem;

      figure {
        width: 0.88rem;
        height: 0.56rem;
        margin-right: 0.24rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        .title-msg {
          font-size: 0.14rem;

          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 0.22rem;
          margin-bottom: 0.08rem;
        }
        .time {
          font-size: 0.12rem;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 0.2rem;
        }
      }
    }
  }
}

.downloadIcon {
  font-size: 0.14rem;
  cursor: pointer;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  line-height: 0.22rem;
  display: flex;
  align-items: center;
  img {
    width: 0.2rem;
    height: 0.16rem;
    margin-left: 0.08rem;
  }
  span {
    flex-shrink: 0;
  }
}

@media (max-width: 768px) {
  .brief-introduction {
    display: none;
  }
  .banner {
    display: none;
  }

  .Phone-download {
    display: block;
  }
  .neirong {
    position: relative;
    .more {
      position: absolute;
      bottom: 0;
      right: 0;
      line-height: 0.22rem;
      background: #f5f5f5;
      color: var(--backgroundColor);
    }
  }
  .twohidden {
    height: 0.44rem;
    overflow: hidden;
  }
  .index-body {
    margin-top: 0;
    background: #ffffff;
    padding-bottom: 0.51rem;
    .msg {
      padding: 0.16rem 0.24rem;
      span {
        display: block;
        font-size: 0.14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 0.22rem;
      }
      span:nth-child(1) {
        margin-bottom: 0.08rem;
      }
    }
    .buttons {
      display: flex;
      div {
        width: 1.87rem;
        height: 0.4rem;
        background: #f6f6f6;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.14rem;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 0.22rem;
        &:first-child {
          border-right: 0.01rem solid #ffffff;
        }
        i {
          margin-left: 0.08rem;
          font-weight: 600;
        }
        img {
          margin-left: 0.08rem;
          width: 0.2rem;
          height: 0.16rem;
        }
      }
      /deep/ .el-icon-arrow-up::before {
        color: var(--backgroundColor);
      }
    }
    .Phoneexpand {
      border-top: solid 0.01rem #eeeeee;

      background: #f8f8f8;
      padding: 0.16rem 0.24rem;
      .expand-title {
        font-size: 0.14rem;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 0.22rem;
        margin-bottom: 0.16rem;
        .content {
          display: flex;
        }

        .textLsjc {
          // font-size: 0.14rem;
          /* 设置多行文本溢出省略 */
          // display: -webkit-box;
          /* 默认最多显示3行 */
          // -webkit-line-clamp: 2;
          // overflow: hidden;
          -webkit-box-orient: vertical;
        }
        .textLsjcOpen {
          font-size: 0.14rem;
          /* 设置多行文本溢出省略 */
          display: -webkit-box;
          /* 默认最多显示3行 */
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        /* 按钮右下角文字环绕布局 */
        /* 设置伪元素是为了将按钮挤到底部 */
        .textLsjc::before {
          content: "";
          float: right;
          width: 0;
          /* 因为text的height是动态改变的，所以得设置text父元素flex布局，否则下面的height用100%设置无效*/
          /* 19px为按钮文字高度 */
          /* height: calc(100% - 19px); */
          /* margin实现，性能比calc要好 */
          height: 100%;
          margin-bottom: -19px;
        }

        .jyfwbtn {
          float: right;
          clear: both;
          cursor: pointer;
          color: var(--backgroundColor);
          //  background: #eeeeee;
          line-height: 16px;
          padding: 0px 6px;
        }

        /* 伪元素设置按钮文本*/
        .jyfwbtn::after {
          content: "更多";
        }

        /* 隐藏checkbox */
        #exp {
          display: none;
        }

        /* 通过兄弟选择器设置展开后的样式 */
        #exp:checked + .textLsjc {
          /* 展开设置一个足够大的行数 */
          -webkit-line-clamp: 999;
        }

        #exp:checked + .textLsjc .jyfwbtn::after {
          content: "收起";
        }
      }
      .expand-name {
        font-size: 0.14rem;
        margin-bottom: 0.08rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 0.22rem;
      }
      .downloadIcon {
        font-size: 0.14rem;
        margin-bottom: 0.16rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 0.22rem;
        span {
          margin-right: 0.17rem;
        }
      }
    }
  }
}
</style>